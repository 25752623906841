const strings = {
  portfolio: {
    username: 'Fae Utero',
    website: 'https://www.faeutero.com/',
    developer: 'Ankit Mahendru',
    developerwebsite: 'https://www.ankitmahendru.com/',
    reactjswebsite: 'https://reactjs.org/',
    netlifywebsite: 'https://www.netlify.com/',
    firebasewebsite: 'https://firebase.google.com/',
    about: "It's all in the music",
    spotifyartistlink:
      'spotify:artist:2jgvsnDdf6qCve1pikbDqh?si=C558K4AWRuuDgo3agXa0iA',
    bandcampURL: 'https://fae-utero.bandcamp.com/',
    hobyoutubeplaylist:
      'https://youtube.com/playlist?list=PLk00mpauZ5XGwLEu2Jm3IYQzwRJq54iJN',
    hobmerch:
      'https://my-store-11630664.creator-spring.com/listing/hill-of-beans',
    bandDescription:
      'Their debut album, Hill of Beans is a raw and emotional journey through the tumultuous years of teenage life, capturing the essence of angst, love, loss, and resilience. Each track delves into the depths of adolescent experiences, from navigating the complexities of love and relationships to grappling with the profound impact of loss.\n\nAs an independent artist, their lyrics are grounded in substantial thought and reflection. The band\'s name, "Fae Utero" translates to "from the womb". "Fae" is a Scottish preposition meaning "from" and "utero" is a Latin term meaning "the womb".\n\nAlso, be sure to check out their latest singles: ‘i guess’ and \'fur cough\'.',
    youtubelinkltsingle: 'https://youtu.be/dPy0OyQblco',
    youtubelinkhwsingle: 'https://youtu.be/IOoh14F3e0k',
    introspectsingle: 'https://youtu.be/FH43bDMRG9s',
    social: {
      youtube: 'https://www.youtube.com/channel/UCg2zJzFO0yrYL7YZ1aHXxkg',
      spotify: 'https://open.spotify.com/album/0Kn6DjslFyS4Esu1yZvOoS',
      applemusic: 'https://music.apple.com/gb/album/hill-of-beans/1710124819',
      bandcamp: 'https://fae-utero.bandcamp.com/album/hill-of-beans',
      instagram: 'https://www.instagram.com/anonymahendru/',
    },
    gdpr: 'Just a moment! This website uses cookies to enhance the user experience. If you continue to browse this site, you are agreeing to our use of cookies. You can disable cookies at any time within your browser settings.',
    gdprbutton: 'Aight bro',
    latestrelease: 'LATEST RELEASE',
    contact: {
      title: 'How can I help?',
      email: 'faeutero@gmail.com',
    },
    shop: {
      pagetitle: 'Shop',
      descr: 'FAE UTERO Official Shop',
      shopProductsClass: 'shop__products__poi',
      shopeCartItemClass: 'shop__cart',
    },
  },
};

export default strings;
