import React, { forwardRef, useState } from 'react';
import songs from '../../Songs';
import './Songs.scss';

const Songs = forwardRef((props, ref) => {
  const [visibleSongId, setVisibleSongId] = useState(null);

  const showLyric = (e, id) => {
    e.preventDefault();
    setVisibleSongId(visibleSongId === id ? null : id);
  };

  return (
    <section className='songs__section' ref={ref}>
      <h1>LYRICS</h1>
      <ul className='songs__list'>
        {songs.map((song) => (
          <li key={song.id} className='songs__item'>
            <div className='songs__info'>
              <button
                onClick={(e) => showLyric(e, song.id)}
                className='songs__name'
              >
                {song.name}
              </button>
            </div>
            {visibleSongId === song.id && (
              <pre className='songs__lyric'>{song.lyric}</pre>
            )}
          </li>
        ))}
      </ul>
    </section>
  );
});

export default Songs;
